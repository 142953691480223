html, body {
  font-family: 'Poppins', sans-serif;
}

.bg-brand {
  background-color: #eb268f;
}

.text-brand {
  color: #eb268f;
}

.text-merah {
  color: #e94524;
}

.text-merah2 {
  color: #ff0000;
}

.text-hijau2 {
  color: #00ff00;
}

.text-hijau {
  color: #088f11;
}

.text-orange {
  color: #ff7f00;
}

.nama-barang {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 3.5rem;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.fade-in {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.fade-out {
  -webkit-animation: fadeout 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 2s; /* Firefox < 16 */
  -ms-animation: fadeout 2s; /* Internet Explorer */
  -o-animation: fadeout 2s; /* Opera < 12.1 */
  animation: fadeout 2s;
}

.footer img {
  display: inline;
  margin: 2px;
}

.daftar-kategori {
  height: 250px;
}

@media screen and (min-width: 450px) {
  .daftar-kategori {
      height: 500px;
  }
}
